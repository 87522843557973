export const ADD_PRODUCT = "ADD_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const REMOVE_QUANTITY = "REMOVE_QUANTITY";
export const DELETE_PRODS = "DELETE_PRODS";
export const addProduct = (product) => ({
  type: ADD_PRODUCT,
  payload: product,
});
export const deleteProduct = (product) => ({
  type: DELETE_PRODUCT,
  payload: product,
});
export const addQuantity = (product) => ({
  type: ADD_QUANTITY,
  payload: product,
});
export const removeQuantity = (product) => ({
  type: REMOVE_QUANTITY,
  payload: product,
});
