import React, { useEffect } from "react";
import Intro from "./Intro.jsx";
import BestSeller from "./BestSellers.jsx";
import MainProducts from "./MainProducts.jsx";
import FinalBanner from "./FinalBanner.jsx";

import EndBannerBackground from "../../../img/foto batman -fondo.png";
import EndBannerFront from "../../../img/20694-3-batman-arkham-knight-hd.png";
import EndBannerProduct from "../../../img/batman-arkham-origins-original-ps3-entrega-inmediata-ya-D_NQ_NP_976686-MCO31060586166_062019-F.png";
const Home = () => {
  useEffect(() => {
    document.title = "TitosDepotCorp - Inicio";
  });
  return (
    <div>
      <Intro />
      <BestSeller />
      <MainProducts />
      <FinalBanner
        backImage={EndBannerBackground}
        frontImage={EndBannerFront}
        title={"Batman Arkham Origins"}
        productImage={EndBannerProduct}
        category={"videojuegos"}
      />
    </div>
  );
};

export default Home;
