import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  useNavMenu,
  useGetRequest,
  axiosPostRequest,
  backendURL,
} from "../../common/utils";
import LogoOrange from "../../../img/titosLg.png";
import { CircularProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../common/Button";
import "./Tracker.css";
const Tracker = () => {
  const [sideMenu, searchMenu, cartMenu, navLink, sideHandler] = useNavMenu();
  let { trackingNumber } = useParams();
  var price = 0;
  const history = useHistory();
  const [{ data: orderData, state: orderState }] = useGetRequest(
    `/orders/${trackingNumber}`
  );
  const [trackerData, setTrackerData] = useState([]);
  const buttonHandler = () => history.push("/");
  const orderProducts =
    orderState &&
    orderData != null &&
    orderData.products.map((product) => {
      price += product._id.price * product.quantity;
      return (
        <div className="product-cont" key={product._id._id}>
          <div className="image">
            <img
              src={product._id.image}
              alt={`${product._id.name}`}
            />
          </div>
          <div className="info">
            <p>{product._id.name}</p>
            <p>
              ${product._id.price}
              <span>x{product.quantity}</span>
            </p>
          </div>
        </div>
      );
    });
  const trackingStatus =
    trackerData.length > 0 &&
    trackerData.map((status) => {
      return (
        <div className="tracker-status" key={`${status.date}`}>
          <div className="check-container">
            <FontAwesomeIcon className="check-icon" icon={["fas", "check"]} />
          </div>
          <p>{status.details}</p>
        </div>
      );
    });
  useEffect(() => {
    document.title = "TitosDepotCorp -> Track";
    const getTrackingData = async () => {
      setTrackerData(
        await axiosPostRequest("/orders/track", {
          carrier: orderData.carrier,
          tracking_number: orderData.tracking,
        })
      );
    };
    if (orderData != null && orderState) getTrackingData();
  }, [orderState]);

  return (
    <div className="tracker-page">
      {searchMenu()}
      {cartMenu()}
      <div className="top-bar">
        <Link to="/">
          <img src={LogoOrange} alt="titosLogo" />
        </Link>
        {navLink()}
        <FontAwesomeIcon
          className="side-icon"
          icon={["fas", "bars"]}
          onClick={sideHandler}
        />
      </div>
      {orderState ? (
        orderData != null ? (
          <div className="tracker-info">
            <div className="tracker-description">
              <h3>
                Estado de la compra <span>{orderData.order_num}</span>
              </h3>
              <h2>Hola {orderData.buyer.name}</h2>
              <div className="user-description">
                <span>datos</span>
                <p>{orderData.buyer.name}</p>
                <p>{orderData.buyer.address}</p>
              </div>
              {trackerData.length > 0 ? (
                <div className="tracker-cont">{trackingStatus}</div>
              ) : (
                <div className="progress-holder">
                  <CircularProgress style={{ color: "#00bd97", size: 60 }} />
                </div>
              )}
              <Button
                label="Seguir comprando"
                onClick={buttonHandler}
                type="other"
              />
            </div>
            <div className="order-description">
              <h2>Products</h2>
              <div className="order-products">{orderProducts}</div>
              <p className="order-total">
                Total: <span>${price} USD</span>
              </p>
            </div>
          </div>
        ) : (
          <p className="wrong">
            No se econtraron resultados para el numero de rastreo:{" "}
            {trackingNumber}{" "}
          </p>
        )
      ) : (
        <div className="progress-holder">
          <CircularProgress
            style={{ color: "#00bd97", width: 200, height: 200 }}
          />
        </div>
      )}
    </div>
  );
};
export default Tracker;
