import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  backendURL,
  axiosPostRequest,
  axiosPutRequest,
} from "../../common/utils";
import axios from "axios";
const ProdContainer = ({ product = {}, pageHandler, jwt, fetchHandler }) => {
  const { register, handleSubmit } = useForm();
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const imgOnChange = (e) => setFile(e.target.files[0]);
  const onSubmitUpdate = async (data) => {
    await axiosPutRequest(`/products/prod/${product._id}`, data, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    handleClick();
    fetchHandler(false);
  };
  const onSubmitNew = async (data) => {
    const posData = new FormData();
    posData.append("file", file);
    const image = await axiosPostRequest("/products/image", posData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwt}`,
      },
    });
    data.image = image.image;
    await axiosPostRequest("/products", data, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    alert("Producto agregado");

    fetchHandler(false);
    pageHandler();
  };
  const handleClick = () => setDisabled(!disabled);
  const handleDelete = async () => {
    const resp = await axios.delete(
      `${backendURL}/products/prod/${product._id}`,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    if (resp.status === 200) {
      alert("Producto eliminado");
      fetchHandler(false);
    }
  };
  const productsRender = () => (
    <form className="form-container" onSubmit={handleSubmit(onSubmitUpdate)}>
      {disabled && (
        <FontAwesomeIcon
          icon={["fas", "edit"]}
          className="edit"
          onClick={handleClick}
        />
      )}
      <FontAwesomeIcon
        icon={["fas", "trash-alt"]}
        className="delete"
        onClick={handleDelete}
      />
      <img src={product.image} />
      <fieldset disabled={disabled}>
        <label>
          nombre:{" "}
          <input
            type="text"
            defaultValue={product.name}
            ref={register}
            name="name"
          />
        </label>
        <label>
          marca:{" "}
          <input
            type="text"
            defaultValue={product.brand}
            ref={register}
            name="brand"
          />
        </label>
        <label>
          precio:{" "}
          <input
            type="num"
            defaultValue={product.price}
            ref={register}
            name="price"
          />
        </label>
        <div>
          <span>peso:</span>
          <label>
            unidad:
            <select
              ref={register}
              name="weight.unit"
              defaultValue={product.weight.unit}
            >
              <option value="g">gramos</option>
              <option value="oz">onzas</option>
              <option value="lb">libras</option>
              <option value="kg">kilogramo</option>
            </select>
          </label>{" "}
          <label>
            cantidad:
            <input
              type="num"
              defaultValue={product.weight.quantity}
              ref={register}
              name="weight.quantity"
            />
          </label>
        </div>
        <div>
          <span>medidas:</span>
          <label>
            unidad:
            <select
              ref={register}
              name="measures.unit"
              defaultValue={product.measures.unit}
            >
              <option value="mm">milimetros</option>
              <option value="cm">centimetros</option>
              <option value="in">pulgadas</option>
              <option value="ft">pies</option>
            </select>
          </label>
          <label>
            alto:
            <input
              type="num"
              defaultValue={product.measures.height}
              ref={register}
              name="measures.height"
            />
          </label>
          <label>
            largo:
            <input
              type="num"
              defaultValue={product.measures.length}
              ref={register}
              name="measures.length"
            />
          </label>
          <label>
            ancho:
            <input
              type="num"
              defaultValue={product.measures.width}
              ref={register}
              name="measures.width"
            />
          </label>
        </div>
        <label>
          sale:{" "}
          <input
            type="checkbox"
            defaultChecked={product.sale}
            ref={register}
            name="sale"
          />
        </label>
        <label>
          descripcion:
          <textarea
            type="text"
            ref={register}
            name="description"
            defaultValue={product.description}
          />
        </label>
        {!disabled && <input type="submit" value="guardar" />}
      </fieldset>
    </form>
  );
  const newProductRender = () => (
    <form className="form-container" onSubmit={handleSubmit(onSubmitNew)}>
      <label>
        imagen:
        <input
          type="file"
          name="img"
          multiple={false}
          accept="image/*"
          onChange={imgOnChange}
        />
      </label>
      <label>
        nombre:
        <input type="text" ref={register} name="name" />
      </label>
      <label>
        marca:
        <input type="text" ref={register} name="brand" />
      </label>
      <label>
        tipo:
        <select ref={register} name="type">
          <option value="celular">celular</option>
          <option value="consola">consola</option>
          <option value="computador">computador</option>
          <option value="electronico">electronico</option>
        </select>
      </label>
      <label>
        precio:
        <input type="num" ref={register} name="price" />
      </label>
      <div>
        <span>peso:</span>
        <label>
          unidad:
          <select ref={register} name="weight.unit" defaultValue="lb">
            <option value="g">gramos</option>
            <option value="oz">onzas</option>
            <option value="lb">libras</option>
            <option value="kg">kilogramo</option>
          </select>
        </label>
        <label>
          cantidad:
          <input type="num" ref={register} name="weight.quantity" />
        </label>
      </div>
      <div>
        <span>medidas:</span>
        <label>
          unidad:
          <select ref={register} name="measures.unit" defaultValue="in">
            <option value="mm">milimetros</option>
            <option value="cm">centimetros</option>
            <option value="in">pulgadas</option>
            <option value="ft">pies</option>
          </select>
        </label>
        <label>
          alto:
          <input type="num" ref={register} name="measures.height" />
        </label>
        <label>
          largo:
          <input type="num" ref={register} name="measures.length" />
        </label>
        <label>
          ancho:
          <input type="num" ref={register} name="measures.width" />
        </label>
      </div>
      <label>
        descripcion:
        <textarea type="text" ref={register} name="description" />
      </label>
      <label>
        sale:
        <input type="checkbox" ref={register} name="sale" />
      </label>
      <input type="submit" value="agregar" />
    </form>
  );
  return Object.keys(product).length > 0
    ? productsRender()
    : newProductRender();
};
export default ProdContainer;
