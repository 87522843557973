import {
  ADD_DATA,
  EDIT_DATA,
  ADD_SHIP,
  DELETE_USER,
  DELETE_SHIP,
} from "../actions/userAction";

const initialState = {
  user: {},
  ship: {},
};
function user(state = initialState, action) {
  switch (action.type) {
    case ADD_DATA:
      return { ...state, user: { ...state.user, ...action.payload } };
    case EDIT_DATA:
      const newData = {
        ...state.user,
        [action.key]: action.payload,
      };
      return { ...state, user: newData };
    case ADD_SHIP:
      return { ...state, ship: action.payload };
    case DELETE_SHIP:
      return { ...state, ship: {} };
    case DELETE_USER:
      return { ...state, user: {} };
    default:
      return state;
  }
}
export default user;
