import React from "react";
import { axiosPostRequest } from "../../common/utils";
import { useForm } from "react-hook-form";

const LogIn = ({ logHandler, jwtHandler }) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    const APIdata = await axiosPostRequest("/users/login", data);
    if (APIdata.message) {
      alert("Error, contraseña incorrecta");
    } else {
      logHandler(APIdata.succes);
      jwtHandler(APIdata.jwt);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="admin-form">
        <label>
          Usuario: <input type="text" name="username" ref={register} />
        </label>
        <label>
          Contraseña: <input type="password" name="password" ref={register} />
        </label>
        <input type="submit" value="Ingresar" />
      </form>
    </div>
  );
};
export default LogIn;
