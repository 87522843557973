import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "./Footer.css";
const Footer = ({ aboutUs }) => {
  const Style = {
    display: aboutUs ? "block" : "none",
  };
  const history = useHistory();
  const clickHandler = (url = "") => history.push(url);
  return (
    <div className="footer-container">
      <div style={Style}>
        <div className="about-us-mobile">
          <h4>Nosotros</h4>
          <p>
            Estamos ubicados en Miami, FL cerca del Miami International Airport,
            con accesos a las principales avenidas de la ciudad lo que facilita
            el transporte de todos los equipos por nosotros ofrecidos.
          </p>
          <h4>LEGAL NOTICE</h4>
          <p>
            All trademarks, characters , product names , company names and logos
            appearing on this site are property of their respective owners . All
            information contained on this site, including but not limited to
            publication dates , game covers , and product availability are
            subject to change without notice. Products on this site are for
            display purposes only and does not guarantee availability.
          </p>
        </div>
        <div className="about-us">
          <p>
            Estamos ubicados en Miami, FL cerca del Miami International Airport,
            con accesos a las principales avenidas de la ciudad lo que facilita
            el tansporte de todos los equipos por nosotros ofrecidos. <br />
            <br /> Estamos para servirle en todas sus transacciones. Nuestros
            Servicios son:
            <br />
            <br /> Exportacion de electrónicos a Latinoamerica Venta al por
            mayor de equipos electrónicos FOB Miami
            <br /> Dejenos saber lo que esta buscando, si no lo encuentra en
            nuestra web nosotros podemos buscarlo con nuestros socios. Contamos
            con una red de negocios que nos permite ofrecerle los mejores
            precios del mercado.
            <br />
            <br /> Los precios que manejamos en nuestro web son precios por
            unidad, para precios de volumen envienos un email a
            tito@titosdepot.com
            <br /> AVISO LEGAL
            <br /> Todas las marcas, personajes, nombres de productos, nombres
            de compañías y logotipos que aparecen en este sitio son propiedad de
            sus respectivos dueños. Toda la información contenida en este sitio,
            incluyendo, pero no limitado a las fechas de publicación, portadas
            de juegos, y la disponibilidad de productos están sujetos a cambios
            sin previo aviso. Productos contenidos en este sitio son sólo para
            fines de visualización y no garantizan la disponibilidad.
            <br /> LEGAL NOTICE <br />
            <br /> All trademarks, characters, product names, company names and
            logos appearing on this site are property of their respective
            owners. All information contained on this site, including but not
            limited to publication dates, game covers, and product availability
            are subject to change without notice. Products on this site are for
            display purposes only and does not guarantee availability. <br />
          </p>
        </div>
        <div className="about-us-products">
          <ul>
            <h3> Categorías de productos </h3>
            <li onClick={() => clickHandler("/celular")}>Celulares</li>
            <li onClick={() => clickHandler("/consola")}>Consolas</li>
            <li onClick={() => clickHandler("/electronico")}>Electrónicos</li>
            <li onClick={() => clickHandler("/computador")}>Computadores</li>
          </ul>
        </div>
      </div>
      <div className="footer">
        <p className="title" onClick={() => clickHandler("/contacto")}>
          Contacto
        </p>
        <p>3300 NW 112Th Ave Unit 9 Miami, FL 33172</p>
        <p>Tel: 786-357-4987</p>
        <p>Email: tito@titosdepot.com</p>
      </div>
      <div className="socials">
        <span>
          <a
            href="https://www.facebook.com/TitosDepot/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
        </span>
        <span>
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </span>
      </div>
    </div>
  );
};

export default Footer;
