import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../common/Input";
const HandleError = ({ error }) => {
  if (error) {
    return error.type === "required" ? (
      <span>Campo obligatorio</span>
    ) : (
      <span>Formato incorrecto</span>
    );
  } else return <></>;
};
const UserInfo = ({ handler, button }) => {
  const { register, handleSubmit, control, errors } = useForm();
  const [location, setLocation] = useState({ country: "", region: "" });
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);

  const onSubmit = (data) => {
    handler(data);
    button();
    dispatch({ type: "ADD_DATA", payload: data });
  };

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      dispatch({ type: "DELETE_USER" });
    }
  }, []);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Información del contacto</h3>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          register={register}
          validation={{
            required: true,
            pattern: /^[a-zA-Z0-9._-]+@[[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}$/,
          }}
          child={<HandleError error={errors.email} />}
          long
        />
        <span className="email">Se enviará un correo de confirmación</span>
        <h3>Dirección de envío</h3>
        <Input
          placeholder="Nombre completo"
          name="name"
          register={register}
          validation={{ required: true, pattern: /[a-zA-Z] [a-zA-Z]/ }}
          child={<HandleError error={errors.name} />}
        />
        <Input
          placeholder="Celular"
          name="phone"
          register={register}
          validation={{ required: true, pattern: /[0-9]/ }}
          child={<HandleError error={errors.phone} />}
        />
        <Controller
          control={control}
          name="country"
          render={({ onChange, value, ref }) => (
            <div className="cont long">
              <CountryDropdown
                name="country"
                whitelist={[
                  "AR",
                  "US",
                  "BR",
                  "MX",
                  "CO",
                  "CA",
                  "PE",
                  "VE",
                  "CH",
                  "EC",
                ]}
                value={value}
                onChange={(country) => {
                  onChange(country);
                  setLocation({ ...location, country: country });
                }}
                defaultOptionLabel="Selecciona tu pais"
                showDefaultOption={false}
                valueType="short"
                classes="inp"
              />
              <HandleError error={errors.city} />
            </div>
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="state"
          render={({ onChange, value, ref }) => (
            <div className="cont">
              <RegionDropdown
                country={location.country}
                value={value}
                onChange={(value) => {
                  setLocation({ ...location, region: value });
                  onChange(value);
                }}
                defaultOptionLabel="Estado/Provincia"
                valueType="short"
                countryValueType="short"
                showDefaultOption={false}
                classes="inp"
              />
              <HandleError error={errors.state} />
            </div>
          )}
          rules={{ required: true }}
        />

        <Input
          placeholder="Código póstal"
          name="zip"
          register={register}
          validation={{ required: true, pattern: /\d/ }}
          child={<HandleError error={errors.zip} />}
        />
        <Input
          placeholder="Cuidad"
          name="city"
          register={register}
          validation={{ required: true }}
          child={<HandleError error={errors.city} />}
          long
        />
        <Input
          placeholder="Dirección"
          name="address"
          register={register}
          validation={{ required: true }}
          child={<HandleError error={errors.city} />}
          long
        />
        <Input
          placeholder="Casa, apto, conjunto (Opcional)"
          name="home"
          register={register}
          long
        />
        <div className="form-button">
          <input type="submit" value="continuar" />
        </div>
      </form>
    </div>
  );
};
export default UserInfo;
