import React, { useState } from "react";
import Tracker from "../../img/tracker.png";
import Search from "../../img/search.png";
import BagShop from "../../img/bag_shop.png";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./NavLink.css";
const NavLink = ({ searchHandler, cartHandler, itemsClass, items }) => {
  const { register, handleSubmit } = useForm();
  const [trackerPop, setTracker] = useState("");
  const [trackerPColor, setColor] = useState("#898989");
  const dispatcher = useDispatch();
  const history = useHistory();
  const clickHandler = () => {
    trackerPop === "active" ? setTracker("") : setTracker("active");
    trackerPColor === "#898989" ? setColor("#00bd97") : setColor("#898989");
  };
  const onSubmit = (data) => history.push(`/tracker/${data.tracking_number}`);
  return (
    <div>
      <nav className="navigation">
        <div className={`tracker-container ${trackerPop}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              name="tracking_number"
              ref={register}
              type="text"
              placeholder="Numero de la orden"
            />

            <input type="submit" value="Averiguar" className="button" />
          </form>
        </div>
        <div className={`tracker ${trackerPop}`} onClick={clickHandler}>
          <p style={{ color: trackerPColor }}>Rastrear Pedido</p>
          <img src={Tracker} alt="tracker" />
        </div>
        <img src={Search} alt="search" onClick={searchHandler} />
        <div className="holder" onClick={cartHandler}>
          <img src={BagShop} alt="cart" className="shop-bag" />
          <div className={"with-items " + itemsClass}>
            <p>{items}</p>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default NavLink;
