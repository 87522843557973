import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import PopMenu from "./PopMenu.jsx";
import "./SideMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideMenu = ({ handler, activeClass }) => {
  const productList = ["celulares", "consolas", "electrónicos", "computadores"];
  const linkList = ["celular", "consola", "electronico", "computador"];
  const [mobClass, setMobClass] = useState("");
  const history = useHistory();
  const [mobileTracker, setMobTracker] = useState(false);
  const { register, handleSubmit } = useForm();
  const handleMobTracker = () => {
    setMobTracker(!mobileTracker);
  };
  const onSubmit = (data) => history.push(`/tracker/${data.tracking_number}`);
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        handler();
    };
    const handleResize = () => {
      window.innerHeight < 400
        ? setMobClass("unactive")
        : setMobClass("active");
    };
    window.addEventListener("resize", handleResize);
    if (activeClass === "active")
      document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapperRef, activeClass]);

  return (
    <div className={"side-menu-container " + activeClass}>
      <div className="side-menu" ref={wrapperRef}>
        <div className="x-icon">
          <FontAwesomeIcon icon={["fas", "times"]} onClick={handler} />
        </div>
        <div className={`side-menu-navigation ${mobClass}`}>
          <Link to="/">
            <p>Inicio</p>
          </Link>
          <div className="pop-menu-holder">
            <PopMenu
              title="Productos"
              icon={<FontAwesomeIcon icon={["fas", "arrow-down"]} />}
              description={productList.map((text, index) => {
                return (
                  <Link key={index} to={"/" + linkList[index]}>
                    <li key={(Math.random() + index).toString(16)}>{text}</li>
                  </Link>
                );
              })}
            />
          </div>
          <Link to="/Contacto">
            <p>Contacto</p>
          </Link>
        </div>
        <div className="side-menu-tracker">
          <p
            onClick={handleMobTracker}
            style={{ color: mobileTracker ? "#00fae7" : "#ffffff" }}
          >
            Rastrear pedido
          </p>
          <div className={mobileTracker ? " active" : "unactive"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                ref={register}
                placeholder="Número de la orden"
                name="tracking_number"
              />
              <input type="submit" value="buscar" className="button" />
            </form>
          </div>
        </div>

        <h4>Términos y condiciones</h4>
        <div className="socials">
          <span>
            <a href="https://www.facebook.com/TitosDepot/" target="_blank">
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          </span>
          <span>
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
