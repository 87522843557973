import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavMenu } from "./utils.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Navigation.css";
import Logo from "../../img/titosLg.png";
const Navigation = () => {
  const [sideMenu, searchMenu, cartMenu, navLink, sideHandler] = useNavMenu();
  const [yPos, setYPos] = useState(0);
  const style = {
    top: 0,
    left: 0,
    width: "100vw",
    zIndex: 5,
    padding: "10px 0",
  };
  const navStyle =
    yPos > 1
      ? { ...style, position: "fixed", backgroundColor: "white" }
      : { ...style, backgroundColor: "transparent", position: "absolute" };
  const navClass = yPos >= 1 ? "on" : "";

  useEffect(() => {
    const handleScroll = () => setYPos(window.pageYOffset);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="">
      {sideMenu()}
      {searchMenu()}
      {cartMenu()}
      <div
        className={"site-header " + navClass}
        style={{ ...style, ...navStyle }}
      >
        <FontAwesomeIcon
          icon={["fas", "bars"]}
          onClick={sideHandler}
          className="menu-icon"
        />
        <Link to="/">
          <img src={Logo} alt="Logo titos" />
        </Link>
        {navLink()}
      </div>
    </div>
  );
};

export default Navigation;
