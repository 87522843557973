import React, { useRef, useEffect } from "react";

import { useParams, useHistory } from "react-router-dom";
import { useGetRequest, backendURL } from "../../common/utils";
import { connect } from "react-redux";
import { Swipeable } from "react-touch";
import { addProduct } from "../../../redux/actions/productAction";
import { CircularProgress } from "@material-ui/core";
import Button from "../../common/Button";
import "./SingleProduct.css";

const SingleProduct = ({ addProduct }) => {
  let { productId, productType } = useParams();
  const productImgs = useRef();
  const history = useHistory();
  const qtyInput = useRef();
  const [{ data: mainProduct, state: isMainFetching }] = useGetRequest(
    `/products/prod/${productId}`
  );
  const [{ data: relatedProducts, state: isRelatedFetching }] = useGetRequest(
    `/products/${productType}`
  );
  // * Components
  const related =
    isRelatedFetching &&
    relatedProducts
      .filter((relatedProduct) => relatedProduct._id !== productId)
      .map((product) => (
        <div key={product._id} className="related-product">
          <div
            onClick={() =>
              history.push(`/Product/${product.type}/${product._id}`)
            }
          >
            <img src={product.image} alt="" />
          </div>
          <p className="title">{product.name}</p>
          <p>${product.price}</p>
          <Button type="add" onClick={() => addProduct(product)} />
        </div>
      ));
  // * Handlers
  const addProductHandler = () => {
    if ((qtyInput.current.value === "")) {
      qtyInput.current.value = 1;
    }
    addProduct({ ...mainProduct, quantity: parseInt(qtyInput.current.value) });
  };
  useEffect(() => {
    if (isMainFetching) document.title = `TitosDepotCorp - ${mainProduct.name}`;
  }, [isMainFetching]);
  return (
    <div className="single-product-container">
      <div className="product-description">
        {isMainFetching ? (
          <>
            <div className="images" ref={productImgs}>
              <div className="image-holder active">
                <img
                  src={mainProduct.image}
                  alt="product main"
                />
              </div>
            </div>
            <div className="description">
              <h3>{mainProduct.name}</h3>
              <h4>${mainProduct.price}</h4>
              <p>{mainProduct.description}</p>
              <div className="action-holder">
                <input type="number" placeholder="Unidades" ref={qtyInput} />
                <Button type="add" onClick={addProductHandler} />
              </div>
            </div>
          </>
        ) : (
          <div className="progress-holder">
            <CircularProgress style={{ color: "#00bd97", size: 60 }} />
          </div>
        )}
      </div>
      <h2>Productos relacionados</h2>
      <div className="related-product-container">
        {isRelatedFetching ? (
          related
        ) : (
          <div className="progress-holder">
            <CircularProgress
              style={{
                color: "#00bd97",
                size: 60,
                display: "block",
                margin: "auto",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    cartProduct: state.product.products,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (product) => dispatch(addProduct(product)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
