import React, { useState, useRef } from "react";

const PopMenu = ({ title, icon, description }) => {
  const [isPoped, openPopMenu] = useState(false);
  const handlePopMenu = () => openPopMenu(!isPoped);
  const refContent = useRef();
  const descriptionStyle = {
    display: isPoped ? "block" : "none",
    padding: isPoped ? "15px 17px" : "0px 17px",
  };
  const titleStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "15px",
    cursor: "pointer",
  };
  const iconStyle = {
    transition: "transform 500ms ease",
    transform: isPoped ? "rotate(180deg)" : "rotate(0deg)",
  };
  return (
    <div>
      <div onClick={handlePopMenu} style={titleStyle}>
        <span>{title}</span>
        <span style={iconStyle}>{icon}</span>
      </div>
      <div style={descriptionStyle} ref={refContent}>
        {description}
      </div>
    </div>
  );
};

export default PopMenu;
