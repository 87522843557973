import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogIn from "./LogIn";
import LogoOrange from "../../../img/titosLg.png";
import "./Dashboard.css";
import Products from "./Products";
import Orders from "./Orders";
const Selector = ({ page, setPage, jwt }) => {
  switch (page) {
    case null:
      return (
        <div className="botones">
          <button onClick={() => setPage("products")}>Productos</button>
          <button onClick={() => setPage("orders")}>Ordenes</button>
        </div>
      );
    case "products":
      return <Products jwt={jwt} setPage={setPage} />;
    case "orders":
      return <Orders jwt={jwt} setPage={setPage} />;
    default:
      return <></>;
  }
};
//<FontAwesomeIcon icon={"long-arrow-alt-left"} />
const Dashboard = () => {
  const [isLogged, setLogged] = useState(false);
  const [jwt, setJwt] = useState(null);
  const [currentPage, setPage] = useState(null);
  return (
    <div className="dashboard">
      <div className="top-bar">
        <Link to="/">
          <img src={LogoOrange} alt="titosLogo" />
        </Link>
        {isLogged && (
          <FontAwesomeIcon
            className="logout"
            icon={["fas", "sign-out-alt"]}
            onClick={() => {
              setLogged(false);
              setJwt("");
            }}
          />
        )}
      </div>
      <>
        {isLogged ? (
          <Selector page={currentPage} setPage={setPage} jwt={jwt} />
        ) : (
          <LogIn logHandler={setLogged} jwtHandler={setJwt} />
        )}
      </>
    </div>
  );
};
export default Dashboard;
