import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { backendURL } from "../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CartProduct = ({
  product,
  deleteProduct,
  addQuantity,
  removeQuantity,
  quantity,
}) => {
  const quantityValue = useSelector(
    (state) => state.product.products[product._id].quantity
  );
  const [units, setUnits] = useState(quantityValue);
  useEffect(() => {
    setUnits(quantityValue);
  }, [quantityValue]);
  const handleUp = () => {
    setUnits(quantityValue + 1);
    addQuantity(product);
  };
  const handleDown = () => {
    quantityValue > 1 ? setUnits(quantityValue - 1) : setUnits(1);
    removeQuantity(product);
  };
  return (
    <div className="cart-product">
      <img src={product.image} alt="" />
      <p className="title">{product.name}</p>
      <FontAwesomeIcon
        icon={["fas", "times"]}
        className="icon"
        onClick={() => deleteProduct(product._id)}
      />
      <div className="counter">
        <button onClick={handleDown}>-</button>
        <p>{units}</p>
        <button onClick={handleUp}>+</button>
      </div>
      <p className="price">${product.price * units}</p>
    </div>
  );
};

export default CartProduct;
