import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Background from "./Background.jsx";
import Footer from "./Footer.jsx";
import Navigation from "./Navigation.jsx";
import SideMenu from "./SideMenu";
import SearchMenu from "./SearchMenu.jsx";
import ShopCart from "./ShopCart";
import NavLink from "./NavLink";

export function useMainLayout(
  page,
  url,
  vWidth,
  vHeight,
  decoration,
  footerInfo,
  activeBg
) {
  const MainView = () => {
    return (
      <div>
        <Background
          url={url}
          vWidth={vWidth}
          vHeight={vHeight}
          decoration={decoration}
          active={activeBg}
        />
        <Navigation />
        {page}
        <Footer aboutUs={footerInfo} />
      </div>
    );
  };
  return MainView;
}
export function useMenuHandler(initial) {
  const [isOpen, openMenu] = useState(initial);
  const Styles = isOpen ? "active" : "unactive";
  return [Styles, () => openMenu(!isOpen), isOpen];
}

export const round5 = (number) => Math.ceil(number / 5) * 5;
export const roundMin = (number) =>
  round5(
    Math.ceil(number / Math.pow(10, number.toString().length - 1)) *
      Math.pow(10, number.toString().length - 1)
  );

export const roundMax = (number) =>
  round5(
    Math.floor(number / Math.pow(10, number.toString().length - 1)) *
      Math.pow(10, number.toString().length - 1)
  );
export const backendURL = "https://titos-depot-api.onrender.com";
export const axiosGetWithParams = (url = "", params) => {
  return axios
    .get(`${backendURL}${url}`, {
      params: params,
    })
    .then((response) => response.data)
    .catch((err) => console.log(err.toJSON()));
};

export const axiosPostRequest = (url = "", body = {}, config = {}) => {
  return axios
    .post(`${backendURL}${url}`, body, config)
    .then((resp) => resp.data)
    .catch((err) => err.toJSON());
};
export const axiosPutRequest = (url = "", body = {}, config = {}) => {
  return axios
    .put(`${backendURL}${url}`, body, config)
    .then((resp) => resp.data)
    .catch((err) => err.toJSON());
};

export const useGetRequest = (url = "") => {
  const [data, setData] = useState({ data: {}, state: false });
  useEffect(() => {
    axios
      .get(`${backendURL}${url}`)
      .then((response) => setData({ data: response.data, state: true }))
      .catch((err) => console.log(err.toJSON()));
  }, [url]);

  return [data, setData];
};

export function useFetch(url) {
  const [data, setData] = useState([]);
  const [fetched, setFecth] = useState(false);
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((product) => {
        setData(product);
        setFecth(true);
      });
  }, [url]);
  return [data, fetched];
}
export function useStore() {
  const storedProducts = useSelector((state) => state.product.products);
  return [storedProducts, Object.keys(storedProducts).length];
}
export function useTotalPrice(object = {}) {
  var totalPrice = 0;
  var totalQty = 0;
  Object.keys(object).forEach((id) => {
    totalPrice += object[id].product.price * object[id].quantity;
    totalQty += object[id].quantity;
  });
  return [parseFloat(totalPrice).toFixed(2), totalQty];
}
export function useNavMenu() {
  const [sideClass, sideHandler] = useMenuHandler(false);
  const [searchClass, searchHandler] = useMenuHandler(false);
  const [cartClass, cartHandler] = useMenuHandler(false);
  const [products, items] = useStore();
  const [totalPrice] = useTotalPrice(products);
  const itemsClass = items > 0 ? "active" : "unactive";
  const sideMenu = () => (
    <SideMenu handler={sideHandler} activeClass={sideClass} />
  );
  const searchMenu = () => (
    <SearchMenu handler={searchHandler} activeClass={searchClass} />
  );
  const cartMenu = () => (
    <ShopCart
      handler={cartHandler}
      activeClass={cartClass}
      total={totalPrice}
    />
  );
  const navLink = () => (
    <NavLink
      searchHandler={searchHandler}
      cartHandler={cartHandler}
      itemsClass={itemsClass}
      items={items}
    />
  );
  return [sideMenu, searchMenu, cartMenu, navLink, sideHandler];
}
export function useForms(forms) {
  const [formData, setFormData] = useState();
  const [formPos, setFormPos] = useState(1);
  const dataHandler = (data) => setFormData({ ...formData, data });
  const formHandlerMinus = () =>
    formPos === 1 ? setFormPos(forms) : setFormPos(formPos - 1);
  const formHandlerPlus = () =>
    formPos > forms - 1 ? "" : setFormPos(formPos + 1);
  const formHandler = (form) => setFormPos(form);
  const classHandler = { 1: "active", 2: "unactive", 3: "unactive" };
  return [
    { formData, formPos },
    { dataHandler, formHandlerPlus, formHandlerMinus, formHandler },
    classHandler,
  ];
}
