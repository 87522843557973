import React from "react";
import Button from "../../common/Button.jsx";
import "./FinalBanner.css";
const FinalBanner = ({
  backImage,
  frontImage,
  title,
  productImage,
  category,
}) => {
  return (
    <div className="back-banner">
      <img src={backImage} alt="end banner img" className="back-image"/>
      <img className="front-banner" src={frontImage} alt="front banner img" />
      <div className="end-banner-product">
        <h3>{title}</h3>
        <img src={productImage} alt="product img" />
        <div className="buttons">
          <Button type="buy" />
          <Button type="" category={category} banner />
        </div>
      </div>
    </div>
  );
};
export default FinalBanner;
