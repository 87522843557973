const initialState = {
  track_info: {},
};
function tracking(state = initialState, action) {
  switch (action.type) {
    case "SET_TRACK":
      return { ...state, track_info: action.payload };
    case "REMOVE_TRACK":
      return initialState;
    default:
      return state;
  }
}
export default tracking;
