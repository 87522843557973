import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import CartProduct from "./CartProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  deleteProduct,
  addQuantity,
  removeQuantity,
} from "../../redux/actions/productAction";
import { Link } from "react-router-dom";
import "./ShopCart.css";
import Button from "./Button";
const ShopCart = ({
  handler,
  activeClass,
  products,
  deleteProduct,
  removeQuantity,
  addQuantity,
  total,
}) => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        handler();
    };
    if (activeClass === "active")
      document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, activeClass]);
  return (
    <div className={activeClass + " shop-cart"}>
      <div className="cart-container" ref={wrapperRef}>
        <div className="container-title">
          <h3>Carrito de compras</h3>
          <FontAwesomeIcon
            icon={["fas", "times"]}
            onClick={handler}
            className="icon"
          />
        </div>
        <div className="cart-content">
          <div className="cart-products-container">
            {Object.entries(products).length > 0 &&
              Object.keys(products).map((productId) => {
                return (
                  <CartProduct
                    product={products[productId].product}
                    deleteProduct={deleteProduct}
                    removeQuantity={removeQuantity}
                    addQuantity={addQuantity}
                    quantity={products[productId].quantity}
                    key={productId}
                  />
                );
              })}
          </div>
          <div className="cart-footer">
            <div>
              <p>Subtotal:</p>
              <span>{total} USD</span>
            </div>
            <Link to="/pago">
              <Button type="other" label="Escoger metodo de envío y pago" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.product.products,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteProduct: (product) => dispatch(deleteProduct(product)),
    addQuantity: (product) => dispatch(addQuantity(product)),
    removeQuantity: (product) => dispatch(removeQuantity(product)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShopCart);
