import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  round5,
  useMenuHandler,
  useGetRequest,
  backendURL,
  roundMax,
  roundMin,
  axiosGetWithParams,
} from "../../common/utils.jsx";
import { useForm } from "react-hook-form";
import Button from "../../common/Button.jsx";
import "./ProductPage.css";
import RigthArrow from "../../../img/Asset 16.png";
import LeftArrow from "../../../img/Asset 17.png";
import { addProduct } from "../../../redux/actions/productAction.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";

const ProductPage = ({ products, addProduct }) => {
  let ranges = [];
  let maxPrice = 0;
  let minPrice = 1000;
  let diff;
  const [priceInputs, setPriceInputs] = useState({});
  const [priceDone, setPriceDone] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [{ data, state }, setData] = useGetRequest(`/products/${products}`);
  const [mainData] = useGetRequest(`/products/${products}`);
  const [index, setIndex] = useState(0);
  const [mobFilterStyle, mobFilterHandler] = useMenuHandler(false);
  const brands = [];
  //* Componets
  const filter =
    mainData.state &&
    mainData.data.map((product, index) => {
      maxPrice = product.price > maxPrice ? product.price : maxPrice;
      minPrice = product.price < minPrice ? product.price : minPrice;
      const brandFilters = !brands.includes(product.brand) &&
        brands.push(product.brand) && (
          <div className="brand" key={product._id}>
            <input
              type="checkbox"
              value={product.brand}
              name="brand"
              ref={register}
            />
            <span className="checkmark" />
            <label> {product.brand}</label>
          </div>
        );

      if (index === mainData.data.length - 1) {
        diff = round5(maxPrice - minPrice);
        ranges.push({ min: round5(minPrice) });
        for (let i = 0; i < 0.75; i += 0.25) {
          ranges.push({
            min: round5(minPrice + diff * i),
            max: round5(minPrice + diff * (i + 0.25)),
          });
        }
        ranges.push({ max: round5(minPrice + diff * 0.75) });
      }
      return brandFilters;
    });
  let inputs = {};
  const priceRanges = ranges.map((range, indx) => {
    let value = [],
      label = "";
    inputs[indx] = false;

    if (range.min && range.max) {
      value = [range.min, range.max];
      label = `$${range.min} a $${range.max}`;
    } else {
      if (range.min) {
        value = [range.min];
        label = `Menos de $${range.min}`;
      }
      if (range.max) {
        value = [range.max];
        label = `Mas de $${range.max}`;
      }
    }
    if (priceDone && indx === ranges.length - 1) {
      setPriceInputs(inputs);
      setPriceDone(false);
    }
    return (
      <div
        className={priceInputs[indx] ? "brand checked" : "brand"}
        key={`${range.max}-${indx}`}
      >
        <input
          type="radio"
          value={value}
          name="range"
          ref={register}
          checked={priceInputs[indx]}
          defaultChecked={priceInputs[indx]}
          onClick={() => handleChange(indx)}
        />
        <span className="checkmark" />
        <label>{label}</label>
      </div>
    );
  });
  const productData =
    state &&
    data.map((product) => {
      return (
        <div className="product-container" key={product._id}>
          <div className="image-holder">
            <Link to={`/Product/${products}/${product._id}`}>
              <img src={product.image} alt="" />
            </Link>
          </div>
          <p className="title">{product.name}</p>
          <p>${product.price}</p>
          <Button type="add" onClick={() => addProduct(product)} />
        </div>
      );
    });
  const data_ = state && productData.slice(9 * index, 9 * (index + 1));

  // * Handlers

  const onSubmit = async (formData) => {
    let params = {
      brand: formData.brand,
      price: formData.range,
      type: products,
    };
    if (formData.range) {
      formData.range = formData.range.split(",");
      if (formData.range.length > 1) {
        params.price = {
          $gte: parseInt(formData.range[0]),
          $lte: parseInt(formData.range[1]),
        };
      } else {
        params.price =
          parseInt(formData.range[0]) < minPrice + diff * 0.25
            ? { $lte: parseInt(formData.range[0]) }
            : { $gte: parseInt(formData.range[0]) };
      }
    }
    setData({
      data: await axiosGetWithParams("/products/filter", params),
      state: true,
    });
  };

  const handleLeft = () => {
    window.scrollTo(0, 100);
    index > 0 ? setIndex(index - 1) : setIndex(index);
  };
  const handleRigth = () => {
    window.scrollTo(0, 100);
    index < Number.parseInt(productData.length / 9)
      ? setIndex(index + 1)
      : setIndex(index);
  };
  const handleSearch = () => {
    setData({ data: {}, state: false });
    if (mobFilterStyle === "active") mobFilterHandler();
  };
  const handleChange = (id) => {
    let toPrice = {};
    Object.keys(priceInputs).forEach((k) => {
      toPrice[k] = k == id ? !priceInputs[k] : false;
    });
    setPriceInputs(toPrice);
  };
  useEffect(() => {
    document.title = `TitosDepotCorp - ${products
      .charAt(0)
      .toUpperCase()}${products.slice(1)}`;
  });
  return (
    <div className="products">
      <div className="products-title">
        <h2>{products}</h2>
      </div>
      <div className="container">
        <div className="filter">
          <Button label="Filtrar" onClick={mobFilterHandler} />
          <div className={`mobile-filtered ${mobFilterStyle}`}>
            <div className="x-icon">
              <FontAwesomeIcon
                icon={["fas", "times"]}
                onClick={mobFilterHandler}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="brands">
                <p>Marca</p>
                <>{filter}</>
              </div>
              <div className="mob-price brands">
                <p>Precio</p>
                <>{priceRanges}</>
              </div>

              <div className="price">
                <p>Precio</p>
                <>{priceRanges}</>
              </div>
              <Button type="search" onClick={handleSearch} />
            </form>
          </div>
        </div>
        {state ? (
          <>
            <div className="filtered">{data_}</div>
            <div className="handlers">
              <div className="arrows">
                <button onClick={handleLeft}>
                  <img src={LeftArrow} alt="left arrow" />
                </button>
                <button onClick={handleRigth}>
                  <img src={RigthArrow} alt="rigth arrow" />
                </button>
              </div>
              <div className="page-counter">
                <p>
                  {index + 1} de {Number.parseInt(productData.length / 9) + 1}
                </p>
              </div>
            </div>
          </>
        ) : (
          <CircularProgress
            style={{
              color: "#00bd97",
              gridColumn: "2/3",
              display: "block",
              margin: "auto",
            }}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    cartProduct: state.product.products,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (product) => dispatch(addProduct(product)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
