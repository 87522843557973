import React, { useEffect, useState } from "react";
import { useGetRequest, backendURL } from "../../common/utils";
import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Orders = ({ jwt, setPage }) => {
  const [{ data: orderData, state: orderState }] = useGetRequest("/orders");
  console.log(orderData);
  const orders =
    orderState &&
    orderData.map((o) => (
      <div className="order-container" key={o._id}>
        <div className="buyer-info">
          <h4>Comprado por:</h4>
          <p>{o.buyer.name}</p>
          <p>{o.buyer.email}</p>
          <p>{o.buyer.address}</p>
        </div>
        <div className="products-info">
          <h4>Productos:</h4>
          {o.products.map((p) => (
            <div key={`Legend_${p._id._id}`}>
              <img src={p._id.image} alt="prod" />
              <p className="name">{p._id.name}</p>
              <p>{p._id.price}</p>
              <p>{p.quantity}</p>
            </div>
          ))}
        </div>
      </div>
    ));
  return (
    <>
      <FontAwesomeIcon
        icon={"long-arrow-alt-left"}
        onClick={() => setPage(null)}
        className="arrow"
      />
      {orderState ? (
        <div className="order">{orders}</div>
      ) : (
        <CircularProgress style={{ color: "#00bd97" }} />
      )}
    </>
  );
};
export default Orders;
