import React, { useState, useEffect } from "react";
import "./Contact.css";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { axiosPostRequest } from "../../common/utils";
import Button from "../../common/Button";
const HandleError = ({ error }) => {
  if (error) {
    return error.type === "required" ? (
      <span>Campo obligatorio</span>
    ) : (
      <span>Formato incorrecto</span>
    );
  } else return <></>;
};
const Contact = () => {
  const { register, handleSubmit, errors } = useForm();
  const [isDone, setDone] = useState(false);
  const history = useHistory();
  const onSubmit = async (data) => {
    const resp = await axiosPostRequest("/orders/contact", data);
    console.log(resp);
    if (resp.message) {
      setDone(true);
    }
  };
  const buttonHandler = () => history.push("/inicio");
  useEffect(() => {
    document.title = "TitosDepotCorp - Contacto";
  });
  return (
    <div className="contact">
      <h2>Contacto</h2>
      <div className="contact-container">
        {isDone ? (
          <div className="contact-done">
            <h3>Gracias por contactarnos, estaremos en contacto contigo</h3>
            <Button
              label="Seguir comprando"
              onClick={buttonHandler}
              type="other"
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <p>Datos</p>
            <input
              name="fullName"
              ref={register({ required: true })}
              placeholder="Nombre"
            />
            {errors.fullName && <HandleError error={errors.fullName} />}
            <input
              name="email"
              ref={register({
                required: true,
                pattern: /^[a-zA-Z0-9._-]+@[[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}$/,
              })}
              placeholder="Email"
            />
            {errors.email && <HandleError error={errors.email} />}
            <textarea
              name="msg"
              ref={register({ required: true })}
              placeholder="Mensaje"
            />
            {errors.msg && <HandleError error={errors.msg} />}
            <input type="submit" value="Enviar" className="button" />
          </form>
        )}

        <div className="information">
          <div className="socials">
            <span>
              <a href="https://www.facebook.com/TitosDepot/" target="_blank">
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>
            </span>
            <span>
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </span>
          </div>
          <div className="tel">
            <h4>Telefonos:</h4>
            <p>305 300 3437</p>
            <p>787 357 4987</p>
          </div>
          <p>titosdepot@email.com</p>
        </div>
      </div>
    </div>
  );
};
export default Contact;
