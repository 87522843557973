import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { useTotalPrice, axiosPostRequest } from "../../common/utils";

const HandleError = ({ error }) => {
  if (error) {
    return <span>Debes selecionar una opcion de envio</span>;
  } else return <></>;
};

const ShipmentInfo = ({
  data,
  handler,
  button,
  formMinus,
  carrier,
  setCarrier,
}) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const dispatcher = useDispatch();
  const fullAddRef = useRef();
  const emailRef = useRef();
  const carrierData = useSelector((state) => state.user.ship);

  const userData = useSelector((state) => state.user.user);
  const prodData = useSelector((state) => state.product.products);
  const [prodPrice, prodQty] = useTotalPrice(prodData);

  // * Handlers
  const onSubmit = (formData) => {
    
    handler(formData);
    if (carrierData[formData.carrier]) {
      const rateAmount = carrierData[formData.carrier].rates.filter(
        (rate) => rate.object_id === formData.rate
      )[0];
      dispatcher({
        type: "ADD_DATA",
        payload: {
          ...formData,
          amount: rateAmount.amount,
          rate_name: rateAmount.name,
        },
      });
      button();
    }
  };
  const handleEmailChange = () => {
    formMinus();
  };
  const handleAddressChange = () => {
    formMinus();
  };
  const onChange = (e) => {
    setCarrier(e.target.value);
  };
  useEffect(() => {
    const getRates = async () => {
      if (Object.keys(userData).length > 0) {
        dispatcher({
          type: "ADD_SHIP",
          payload: await axiosPostRequest("/ships/rates", {
            addressTo: {
              name: userData.name,
              street1: userData.address,
              city: userData.city,
              state: userData.state,
              zip: userData.zip,
              country: userData.country,
              phone: userData.phone,
              email: userData.email,
            },
            items: {
              quantity: prodQty,
              price: parseFloat(prodPrice),
              totalWeight: totalWeight,
              totalVolume: totalVolume,
            },
          }),
        });
      }
    };
    const fullAdd = userData.country
      ? `${userData.address}, ${userData.city}. ${userData.country}`
      : "";
    fullAddRef.current.children[0].value = fullAdd;
    emailRef.current.children[0].value = userData.email;
    dispatcher({ type: "DELETE_SHIP" });
    var totalWeight = 0,
      totalVolume = 0;
    if (prodQty > 0) {
      prodQty < 2
        ? Object.keys(prodData).forEach((key) => {
            totalWeight += prodData[key].product.weight.quantity;
            totalVolume = {
              h: prodData[key].product.measures.height,
              l: prodData[key].product.measures.length,
              w: prodData[key].product.measures.width,
            };
          })
        : Object.keys(prodData).forEach((key) => {
            totalWeight += prodData[key].product.weight.quantity;
            totalVolume +=
              prodData[key].product.measures.height *
              prodData[key].product.measures.length *
              prodData[key].product.measures.width;
          });
      getRates();
    } else {
      alert("Debes agregar productos!!");
      history.goBack();
    }
  }, [userData.city, userData.country, userData.address]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Datos del contacto</h3>
        <div className="cont long" ref={emailRef}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="inp"
          />
          <div className="input-change" onClick={handleEmailChange}>
            <p>cambiar</p>
          </div>
        </div>
        <div className="cont long" ref={fullAddRef}>
          <input
            type="text"
            name="fulladdress"
            placeholder="Dirección"
            ref={register}
            className="inp"
          />
          <div className="input-change" onClick={handleAddressChange}>
            <p>cambiar</p>
          </div>
        </div>
        <h3>Tipo de envío</h3>
        <span className="sent-price long">
          Usted correrá con el costo de envio
        </span>

        {Object.keys(carrierData).length > 0 ? (
          Object.keys(carrierData).map((key) => {
            return (
              <div className="inp cont long" key={key}>
                <div className="carrier-info">
                  <div className="img-holder">
                    <img src={carrierData[key].image} alt={key} />
                    <p>{key}</p>
                  </div>
                  <div className="checkmark-container">
                    <input
                      type="radio"
                      name="carrier"
                      value={key}
                      ref={register({ required: true })}
                      onChange={onChange}
                    />
                    <span className="checkmark" />
                  </div>
                </div>
                <div className={carrier === key ? "active" : "unactive"}>
                  {carrierData[key].rates.map((singleRate) => {
                    return (
                      <div className="single-rate" key={singleRate.object_id}>
                        <label>
                          ${singleRate.amount}
                          <span>
                            {singleRate.name}, tiempo estimado{" "}
                            {singleRate.estimated_days}
                            {parseInt(singleRate.estimated_days, 10) > 1
                              ? " días hábiles"
                              : " día hábil"}
                          </span>
                        </label>
                        <div className="checkmark-container">
                          <input
                            type="radio"
                            value={singleRate.object_id}
                            name="rate"
                            ref={register({ required: true })}
                          />
                          <span className="checkmark" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="inp cont long progress-holder">
            <CircularProgress style={{ color: "#00bd97" }} />
          </div>
        )}
        {Object.keys(errors).length > 0 && <HandleError error={errors} />}

        <div className="form-button">
          <input type="submit" value="continuar" />
        </div>
      </form>
    </div>
  );
};
export default ShipmentInfo;
