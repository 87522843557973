import React from "react";
import { useHistory } from "react-router-dom";
import { useGetRequest, backendURL } from "../../common/utils";
import { CircularProgress } from "@material-ui/core";
import "./MainProducts.css";
import Button from "../../common/Button.jsx";

const MainProducts = () => {
  const [{ data, state }] = useGetRequest("/products/main");
  const history = useHistory();
  return (
    <div className="main-products">
      {state ? (
        data.some((p) => p !== null) ? (
          data.map((product) => (
            <div className="main-product-container" key={product._id}>
              <h3>{product.name}</h3>
              <p>{product.description}</p>
              <img
                src={product.image}
                onClick={() =>
                  history.push(`Product/${product.type}/${product._id}`)
                }
                alt="prodcutImage"
              />
              <div className="buttons">
                <Button
                  type="buy"
                  onClick={() =>
                    history.push(`Product/${product.type}/${product._id}`)
                  }
                />
                <Button
                  type=""
                  onClick={() => history.push(`/${product.type}`)}
                />
              </div>
            </div>
          ))
        ) : (
          <>
            <p>No se econtraro resultados en la base de datos</p>
          </>
        )
      ) : (
        <CircularProgress
          style={{
            color: "#00bd97",
            display: "block",
            margin: "auto",
          }}
        />
      )}
    </div>
  );
};

export default MainProducts;
