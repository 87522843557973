import React, { useState, useEffect } from "react";
import "./Carrousel.css";
import { Swipeable } from "react-touch";
import { Link, useHistory } from "react-router-dom";
import TransparentArrow from "../../img/Asset 9.png";
import DarkArrow from "../../img/Asset 8.png";

const Carrousel = ({
  images,
  legends,
  position,
  onPositionChanged,
  arrows,
  url,
}) => {
  const history = useHistory();
  useEffect(() => {
    const interval = setInterval(() => {
      if (position < mainFrames.length - 1) {
        onPositionChanged(position + 1);
      } else onPositionChanged(0);
    }, 3000);
    onPositionChanged(position);
    return () => {
      clearInterval(interval);
    };
  }, [position]);

  const handlePlus = () => {
    position === mainFrames.length - 1
      ? onPositionChanged(0)
      : onPositionChanged(position + 1);
  };
  const handleMinus = () => {
    position === 0
      ? onPositionChanged(mainFrames.length - 1)
      : onPositionChanged(position - 1);
  };

  const mainFrames = images.map((img, index) => {
    return position === index ? (
      <img
        className="active"
        src={img}
        alt={"carrousel img n:" + index}
        key={index}
      />
    ) : (
      <img src={img} alt={"carrousel img n:" + index} key={index} />
    );
  });
  const Legends =
    legends != null &&
    legends.map((label, id) => {
      return position === id ? (
        <div key={`Legend_${id}`} className="active">
          <span></span>
          <p> {label}</p>
        </div>
      ) : (
        <div key={`Legend_${id}`}>
          <span></span>
          <p> {label}</p>
        </div>
      );
    });

  return !arrows ? (
    <Swipeable onSwipeLeft={handleMinus} onSwipeRight={handlePlus}>
      <div className="carrousel-holder">
        <div className="carrousel-img" onClick={() => history.push(url)}>
          {mainFrames}
        </div>

        <button onClick={handlePlus}>
          <img src={TransparentArrow} alt="arrow" />
        </button>

        <div className="carrousel-legends">{Legends}</div>
      </div>
    </Swipeable>
  ) : (
    <div className="carrousel-holder-arrows">
      <div className="arrows">
        <button onClick={handlePlus}>
          <img src={TransparentArrow} alt="arrow" />
        </button>
        <button onClick={handleMinus}>
          <img src={DarkArrow} alt="arrow" />
        </button>
      </div>
      <Swipeable onSwipeLeft={handleMinus} onSwipeRight={handlePlus}>
        <div className="carrousel-img" onClick={() => history.push(url)}>
          {mainFrames}
        </div>
      </Swipeable>
      <div className="carrousel-legends">{Legends}</div>
    </div>
  );
};
export default Carrousel;
