import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
/*Components*/
import Button from "../../common/Button.jsx";
import Carrousel from "../../common/Carrousel.jsx";
/*Estilos*/
import "./Intro.css";
/* Images */
import Consoles from "../../../img/Rectangle 1.png";
import Electronics from "../../../img/electronicos-carrousel.png";
import Cellphones from "../../../img/celular.png";
import Videogames from "../../../img/videjuegos-carrousel.png";

const Intro = () => {
  const [pos, setPos] = useState(0);
  const carrouselImages = [Consoles, Electronics, Cellphones, Videogames];
  const productList = ["Consolas", "Electrónicos", "Celulares", "Computadoras"];
  const pathList = ["/consola", "/electronico", "/celular", "/computador"];
  const path = pathList.filter((path, ind) => pos === ind)[0];
  return (
    <div className="intro">
      <div className="intro-container">
        <div className="cont">
          <p>
            <span>Tito's Depot</span> es un negocio en USA que exporta
            electrónicos para toda latinoamérica
          </p>
          <Link to={path}>
            <Button type="buy" />
          </Link>
        </div>
        <Carrousel
          images={carrouselImages}
          legends={productList}
          onPositionChanged={setPos}
          position={pos}
          arrows={false}
          url={path}
        />
      </div>
      <div className="socials">
        <span>
          <a href="https://www.facebook.com/TitosDepot/" target="_blank">
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
        </span>
        <span>
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </span>
      </div>
    </div>
  );
};
export default Intro;
