import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useMainLayout } from "./components/common/utils.jsx";
import store from "./redux/store";

import ScrollTop from "./components/ScrollTop";
import Home from "./components/screens/Home";
import ProductPage from "./components/screens/Products";
import SingleProductPage from "./components/screens/SingleProduct";
import Payment from "./components/screens/Payment";
import Contact from "./components/screens/Contact";
import Tracker from "./components/screens/Tracker";
import Dashboard from "./components/screens/Admin";
import MainBackground from "./img/G0M68V.png";
import ElectronicBackground from "./img/wGOww5.jpg";
import ConsolesBackground from "./img/xbox_one.jpg";
import CellphoneBackground from "./img/CvbzSU.png";
import VideogameBackground from "./img/videogamesbg.jpg";

library.add(fab, fas);

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollTop />
        <Switch>
          <Route path="/admin" component={Dashboard} />
          <Route exact path="/">
            <Redirect to="/inicio" />
          </Route>
          <Route path="/admin" component={Dashboard} />
          <Route
            path="/Electronico"
            component={useMainLayout(
              <ProductPage products={"electronico"} />,
              ElectronicBackground,
              "100%",
              "",
              true,
              false
            )}
          />
          <Route
            path="/Consola"
            component={useMainLayout(
              <ProductPage products={"consola"} />,
              ConsolesBackground,
              "100%",
              "",
              true,
              false
            )}
          />
          <Route
            path="/Celular"
            component={useMainLayout(
              <ProductPage products={"celular"} />,
              CellphoneBackground,
              "100%",
              "",
              true,
              false
            )}
          />
          <Route
            path="/Computador"
            component={useMainLayout(
              <ProductPage products={"computador"} />,
              VideogameBackground,
              "100%",
              "",
              true,
              false
            )}
          />
          <Route
            path="/Product/:productType/:productId"
            component={useMainLayout(
              <SingleProductPage />,
              "",
              "",
              "",
              false,
              true,
              false
            )}
          />
          <Route path="/pago" component={Payment} />
          <Route path="/tracker/:trackingNumber" component={Tracker} />
          <Route
            path="/Contacto"
            component={useMainLayout(
              <Contact />,
              MainBackground,
              "100%",
              " ",
              true,
              false
            )}
          />
          <Route
            path="/inicio"
            component={useMainLayout(
              <Home />,
              MainBackground,
              "100%",
              " ",
              false,
              true
            )}
          />
          <Route exact path="/*">
            <Redirect to="/inicio" />
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
