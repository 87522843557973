import React, { useRef, useEffect, useState } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {
  useNavMenu,
  useForms,
  useTotalPrice,
  backendURL,
} from "../../common/utils";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Payment.css";
import logo from "../../../img/titosLg.png";
import UserInfo from "./UserInfo";
import ShipmentInfo from "./ShipmentInfo";
import PaymentType from "./PaymentType";
import CartProduct from "../../common/CartProduct";

const Payment = () => {
  const shopProds = useSelector((state) => state.product.products);

  const userData = useSelector((state) => state.user.user);
  const history = useHistory();
  const dispatcher = useDispatch();
  const [carrier, setCarrier] = useState(null);
  const [prodPrice] = useTotalPrice(shopProds);
  const shipCost = userData.amount ? parseFloat(userData.amount) : 0;
  const totalPrice = (Number(prodPrice) + Number(shipCost)).toFixed(2);
  const [,searchMenu, cartMenu, navLink, sideHandler] = useNavMenu();
  const [
    { formPos },
    { dataHandler, formHandlerPlus, formHandlerMinus, formHandler },
    classes,
  ] = useForms(3);
  const [continuar, setContinuar] = useState(false);

  const titles = useRef();
  const forms = useRef();
  const minusHandler = () =>
    formPos <= 1 ? history.goBack() : formHandlerMinus();
  const productHandler = (action, product) =>
    dispatcher({ type: action, payload: product });
  const shopCart = () =>
    Object.keys(shopProds).map((product) => {
      return (
        <div className="product" key={`sc-${product}`}>
          <img
            src={shopProds[product].product.image}
            alt={`Product: ${shopProds[product].product.name}`}
          />
          <div className="description">
            <h4>{shopProds[product].product.name}</h4>

            <span>Cantidad</span>
            <p>{shopProds[product].quantity}</p>

            <span>Subtotal</span>
            <p>
              ${shopProds[product].product.price * shopProds[product].quantity}{" "}
            </p>
          </div>
        </div>
      );
    });

  const shopCartMobile = () => {
    return (
      <div className="cart-products-container">
        {Object.entries(shopProds).length > 0 &&
          Object.keys(shopProds).map((productId) => {
            return (
              <CartProduct
                product={shopProds[productId].product}
                deleteProduct={() =>
                  productHandler("DELETE_PRODUCT", shopProds[productId].product)
                }
                removeQuantity={() =>
                  productHandler(
                    "REMOVE_QUANTITY",
                    shopProds[productId].product
                  )
                }
                addQuantity={() =>
                  productHandler("ADD_QUANTITY", shopProds[productId].product)
                }
                quantity={shopProds[productId].quantity}
                key={productId}
              />
            );
          })}
      </div>
    );
  };
  useEffect(() => {
    document.title = "TitosDepotCorp - Pago";
    Array.from(titles.current.children).forEach((title, index) => {
      title.className = index === formPos ? "focused" : "";
    });
    Array.from(forms.current.children).forEach((form, index) => {
      form.className = index === formPos - 1 ? "active" : "unactive";
    });
  }, [formPos, classes]);
  return (
    <div className="payment-page">
      {searchMenu()}
      {cartMenu()}
      <div className="top-bar">
        <Link to="/">
          <img src={logo} alt="titosLogo" />
        </Link>
        {navLink()}
        <FontAwesomeIcon
          className="side-icon"
          icon={["fas", "bars"]}
          onClick={sideHandler}
        />
      </div>
      <div className="payment">
        <div
          className={`${continuar ? "on-screen" : "off-screen"} payment-form`}
        >
          <div className="form-placeholder" ref={titles}>
            <p>Carrito {">"}</p>
            <p>Información {">"}</p>
            <p>Envío {">"}</p>
            <p>Forma de pago</p>
          </div>
          <div className="mobile-form-nav" onClick={minusHandler}>
            <FontAwesomeIcon icon={"long-arrow-alt-left"} />
          </div>
          <div ref={forms}>
            <PayPalScriptProvider
              options={{
                "client-id":
                  "AR35Q1mT2Nj2Tf1wiEpu2067apnfp8cbmXV3vqUHH6eHiQ9ROXC9lb_8LO_S3SJXnQoZMi0geinWxMPG",
                currency: "USD",
                "enable-funding": "card,credit,mercadopago",
              }}
            >
              <UserInfo handler={dataHandler} button={formHandlerPlus} />
              <ShipmentInfo
                handler={dataHandler}
                button={formHandlerPlus}
                formMinus={formHandlerMinus}
                carrier={carrier}
                setCarrier={setCarrier}
              />
              <PaymentType
                handler={dataHandler}
                button={formHandlerPlus}
                formHandler={formHandler}
                totalPrice={totalPrice}
                setCarrier={setCarrier}
              />
            </PayPalScriptProvider>
          </div>
        </div>
        <div
          className={`${
            continuar ? "off-screen" : "on-screen"
          } payment-products`}
        >
          <h3>Carrito de Compras</h3>
          <div className="holder">
            <div className="desktop">{shopCart()}</div>
            <div className="mobile">{shopCartMobile()}</div>
          </div>
          <div className="payment-total">
            <div className="price-cont">
              <span>Envio</span>
              <p>${userData.amount} USD</p>
            </div>
            <div className="price-cont">
              <span>Total</span>
              <p>${totalPrice} USD</p>
            </div>
          </div>
          <div className="mobile-buttons">
            <button onClick={() => setContinuar(!continuar)}>Seguir</button>
            <button className="inverted">Volver</button>
          </div>
        </div>
      </div>
    </div>
  );
};
//PROBAR ACCIONES DE REDUX EN MOBILE
export default Payment;
