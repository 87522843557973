import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import { axiosPostRequest } from "../../common/utils";
const PaymentType = ({
  handler,
  button,
  formHandler,
  totalPrice,
  setCarrier,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const dispatcher = useDispatch();
  const shipData = useSelector((state) => state.user.ship);
  const userData = useSelector((state) => state.user.user);
  const products = useSelector((state) => state.product.products);
  const fullAddRef = useRef();
  const emailRef = useRef();
  const shipRef = useRef();
  const history = useHistory();

  const [isPayed, setPayed] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(null);

  const postProd = Object.keys(products).map((key) => {
    return {
      _id: key,
      quantity: products[key].quantity,
    };
  });
  const onSubmit = (data) => {
    handler(data);
    dispatcher({ type: "DELETE_USER" });
    dispatcher({ type: "DELETE_PRODS" });
    history.push("/inicio");
  };
  const handleInputChange = () => {
    formHandler(1);
    dispatcher({
      type: "DELETE_SHIP",
    });
  };

  const handleShipmentChange = () => {
    formHandler(2);
    setCarrier(null);
  };
  /**Paypal fnc */
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [{ amount: { value: totalPrice } }],
      })
      .then((orderId) => orderId);
  };
  const onApprove = async (data, actions) => {
    const trackingResponse = await axiosPostRequest("/ships/transaction", {
      object_id: userData.rate,
    });
    const orderResponse = await axiosPostRequest("/orders", {
      buyer: {
        name: userData.name,
        address: userData.fulladdress,
        email: userData.email,
      },
      label: trackingResponse.label,
      tracking_url: trackingResponse.tracking_url,
      products: postProd,
      tracking: trackingResponse.tracking,
      carrier: userData.carrier,
      order_num: Math.random().toString(36).substring(2, 7),
    });
    setTrackingNumber(orderResponse.tracking);
    setPayed(true);
    fullAddRef.current.children[1].classList.add("unactive");
    emailRef.current.children[1].classList.add("unactive");
    shipRef.current.children[1].classList.add("unactive");
    return actions.order.capture().then((details) => {});
  };
  const onError = (err) => {
    console.log(err);
  };
  useEffect(() => {
    const fullAdd = userData.country
      ? `${userData.address}, ${userData.city}. ${userData.country}`
      : "";
    fullAddRef.current.children[0].value = fullAdd;
    emailRef.current.children[0].value = userData.email;
  }, [userData]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Datos del contacto</h3>
        <div className="cont long" ref={emailRef}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            errors={errors.email}
            className="inp"
            ref={register}
          />
          <div className="input-change" onClick={handleInputChange}>
            <p>cambiar</p>
          </div>
        </div>
        <div className="cont long" ref={fullAddRef}>
          <input
            type="text"
            name="fulladdress"
            placeholder="Dirección"
            errors={errors.fulladd}
            className="inp"
            ref={register}
          />
          <div className="input-change" onClick={handleInputChange}>
            <p>cambiar</p>
          </div>
        </div>
        <div className="cont long ">
          {userData.carrier && shipData[userData.carrier] ? (
            <div className="rate-holder inp rate" ref={shipRef}>
              <div className="rate-info">
                <img
                  src={shipData[userData.carrier].image}
                  alt="selected carrier"
                />
                <span>{userData.rate_name}</span>
              </div>
              <div className="input-change" onClick={handleShipmentChange}>
                <p>cambiar</p>
              </div>
            </div>
          ) : (
            <div className="progress-holder">
              <CircularProgress style={{ color: "#00bd97", size: 60 }} />
            </div>
          )}
        </div>

        {isPayed ? (
          <div className="payed long">
            <h3>Nº rastreo: {trackingNumber}</h3>
            <p>Con este numero pordras averiguar el estado de tu pedido</p>
            <br></br>
            <p>
              Te enviamos un correo con la informacion de tu compra, gracias por
              comprar con nosotros!
            </p>
            <FontAwesomeIcon className="check-icon" icon={["fas", "check"]} />
          </div>
        ) : (
          <>
            <h3>Tipo de pago</h3>
            <div className="inp long  pay">
              <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
                style={{ shape: "pill", color: "blue", height: 30 }}
                forceReRender={[totalPrice]}
              />
            </div>
          </>
        )}
        {isPayed && (
          <div className="form-button">
            <input type="submit" onClick={button} value="Seguir comprando" />
          </div>
        )}
      </form>
    </div>
  );
};

export default PaymentType;
