import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  ADD_QUANTITY,
  REMOVE_QUANTITY,
  DELETE_PRODS,
} from "../actions/productAction";

const initialState = {
  products: {},
};
function product(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT:
      const product = state.products[action.payload._id]
        ? {
            ...state.products[action.payload._id],
            quantity: action.payload.quantity
              ? action.payload.quantity +
                state.products[action.payload._id].quantity
              : 1 + state.products[action.payload._id].quantity,
          }
        : {
            product: action.payload,
            quantity: action.payload.quantity ? action.payload.quantity : 1,
          };

      return {
        ...state,
        products: { ...state.products, [action.payload._id]: product },
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: Object.keys(state.products).reduce((object, key) => {
          if (key !== action.payload.toString())
            object[key] = state.products[key];
          return object;
        }, {}),
      };
    case ADD_QUANTITY:
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload._id]: {
            ...state.products[action.payload._id],
            quantity: state.products[action.payload._id].quantity + 1,
          },
        },
      };
    case REMOVE_QUANTITY:
      const newState =
        state.products[action.payload._id].quantity > 1
          ? (state.products[action.payload._id] = {
              ...state.products[action.payload._id],
              quantity: state.products[action.payload._id].quantity - 1,
            })
          : (state.products[action.payload._id] = {
              ...state.products[action.payload._id],
            });
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload._id]: newState,
        },
      };
    case DELETE_PRODS:
      return initialState;
    default:
      return state;
  }
}
export default product;
