import React from "react";
import "./Background.css";
const Background = ({ url, vWidth, vHeight, decoration, active = true }) => {
  const Styles = {
    width: vWidth,
    height: vHeight,
    backgroundImage: `url(${url})`,
    display: !active && "none",
  };
  return decoration ? (
    <div className={"decorated-container"}>
      <div style={Styles} className={"decorated"}></div>
      <div
        style={{ width: vWidth, height: vHeight }}
        className={"decoration"}
      />
      <div className="esquina" />
    </div>
  ) : (
    <div style={Styles} className={"normal"}></div>
  );
};
export default Background;
