import React from "react";

const Input = ({
  type,
  name,
  placeholder,
  register,
  validation,

  value,
  label,
  long,
  readOnly,
  child,
  childHandler,
}) => {
  const divClass = `cont ${+long ? "long " : ""}`;
  return (
    <div className={divClass}>
      <input
        className={type === "radio" ? "" : "inp"}
        type={type ? type : "text"}
        placeholder={placeholder}
        name={name}
        ref={register(validation)}
        value={value}
        readOnly={readOnly}
        onClick={childHandler}
      />
      {label && <label>{label}</label>}
      {child}
    </div>
  );
};
export default Input;
