import React, { useState, useEffect } from "react";
import Carrousel from "../../common/Carrousel.jsx";
import Button from "../../common/Button.jsx";
import { CircularProgress } from "@material-ui/core";
import { useGetRequest, backendURL } from "../../common/utils";
import { useHistory } from "react-router-dom";

import "./BestSellers.css";
const BestSellers = () => {
  const history = useHistory();
  const [{ data, state }] = useGetRequest("/products/sale");
  const [pos, setPos] = useState(0);
  const handleClick = () =>
    history.push(`/Product/${data[pos].type}/${data[pos]._id}`);
  return (
    <div className="sales">
      <div className="splitter"></div>
      <h2>
        Lo más <span> vendido</span>
      </h2>
      <div className="sales-container">
        {state ? (
          data.length > 0 ? (
            <>
              <Carrousel
                images={data.map((product) => product.image)}
                onPositionChanged={setPos}
                position={pos}
                arrows={true}
                url={`/Product/${data[pos].type}/${data[pos]._id}`}
              />
              <h3 className="sales-item-title">
                <span>{data[pos].name.split(" ")[0]}</span>
                {data[pos].name.replace(data[pos].name.split(" ")[0], "")}
              </h3>
              <div className="sales-description">
                <p>{data[pos].description}</p>
                <Button type="buy" onClick={handleClick} />
              </div>
            </>
          ) : (
            <>
              <p>No se econtraron resultados en la base de datos</p>
            </>
          )
        ) : (
          <CircularProgress
            style={{
              color: "#00bd97",
              display: "block",
              margin: "auto",
              gridColumn: "1/3",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BestSellers;
