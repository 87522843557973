import React, { useState, useEffect } from "react";
import { axiosGetWithParams } from "../../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";

import ProdContainer from "./ProdContainer";
const Products = ({ jwt, setPage }) => {
  const [cels, setCels] = useState([]);
  const [cons, setCons] = useState([]);
  const [comps, setComps] = useState([]);
  const [elecs, setElecs] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [newProduct, setNewProduct] = useState(false);
  const mapProducts = (products = []) =>
    products.map((p) => (
      <ProdContainer
        product={p}
        key={p._id}
        jwt={jwt}
        fetchHandler={setFetched}
      />
    ));
  const clickHandler = () => setNewProduct(!newProduct);
  const pageHandler = () => setPage(null);
  useEffect(() => {
    const fetchData = async () => {
      setCels(
        await axiosGetWithParams("/products/filter", { type: "celular" })
      );
      setCons(
        await axiosGetWithParams("/products/filter", { type: "consola" })
      );
      setComps(
        await axiosGetWithParams("/products/filter", { type: "computador" })
      );
      setElecs(
        await axiosGetWithParams("/products/filter", { type: "electronico" })
      );
      setFetched(true);
    };
    fetchData();
  }, [fetched]);
  return (
    <div className="products-holder">
      <FontAwesomeIcon
        icon={"long-arrow-alt-left"}
        onClick={() => setPage(null)}
        className="arrow"
      />
      {fetched ? (
        <>
          <h2 className="container-title" onClick={clickHandler}>
            Añadir
            <FontAwesomeIcon icon={["fas", "plus-square"]} className="add" />
          </h2>
          {newProduct ? (
            <div className="new-container">
              <ProdContainer
                pageHandler={clickHandler}
                jwt={jwt}
                fetchHandler={setFetched}
              />
            </div>
          ) : (
            <>
              <h3>Celulares</h3>
              <div className="type-container">{mapProducts(cels)}</div>
              <h3>Consolas</h3>
              <div className="type-container">{mapProducts(cons)}</div>
              <h3>Computadores</h3>
              <div className="type-container">{mapProducts(comps)}</div>
              <h3>Electronicos</h3>
              <div className="type-container">{mapProducts(elecs)}</div>
            </>
          )}
        </>
      ) : (
        <CircularProgress style={{ color: "#00bd97" }} />
      )}
    </div>
  );
};
export default Products;
