import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TitosLogo from "../../img/TitosLogo.png";
import { axiosGetWithParams, backendURL } from "../common/utils";
import "./SearchMenu.css";
import Search from "../../img/search.png";
const SearchMenu = ({ handler, activeClass }) => {
  const [searchParam, setSearchParam] = useState("");
  const history = useHistory();
  const [searchedProducts, setSearchedProducts] = useState([]);
  //TODO implement product search with params
  const clickHandler = (url = "") => {
    setSearchedProducts([]);
    setSearchParam("");
    handler();
    history.push(url);
  };

  useEffect(() => {
    const debounceTime = setTimeout(async () => {
      if (searchParam !== "") {
        setSearchedProducts(
          await axiosGetWithParams("/products/search", {
            search: searchParam,
          })
        );
      }
    }, 2000);
    return () => clearTimeout(debounceTime);
  }, [searchParam]);
  return (
    <div className={"search-menu " + activeClass}>
      <div className="top-search">
        <div className="close-icon" onClick={handler}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </div>
        <div className="logo" onClick={() => clickHandler("home")}>
          <img src={TitosLogo} alt="logo titos white" />
        </div>
      </div>
      <div className="main-search">
        <img src={Search} alt="buscar" />
        <input
          type="text"
          placeholder="buscar"
          autoComplete="off"
          defaultValue={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      <div className="searched-products">
        {searchedProducts !== null &&
          searchedProducts.length > 0 &&
          searchedProducts.map((product) => {
            return (
              <div
                className="searched"
                key={product._id}
                onClick={() =>
                  clickHandler(`/Product/${product.type}/${product._id}`)
                }
              >
                <img
                  src={product.image}
                  alt={product.title}
                />
                <p>{product.name}</p>
              </div>
            );
          })}
      </div>
      <div className="redes-sociales">
        <span>
          <a href="https://www.facebook.com/TitosDepot/" target="_blank">
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
        </span>
        <span>
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </span>
      </div>
    </div>
  );
};
export default SearchMenu;
