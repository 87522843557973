import React, { useState } from "react";
const Button = ({ type, category, banner, onClick, label }) => {
  const [isHovered, setHovered] = useState(false);
  const text =
    type === "buy"
      ? "Comprar ya"
      : type === "add"
      ? "Añadir"
      : type === "search"
      ? "Buscar"
      : "Más ";
  const finalLabel = label ? label : text;
  const mainStyle = {
    borderRadius: "14.2px",
    textTransform: "uppercase",
    fontFamily: "Metropolis",
    fontSize: type === "buy" ? "16px" : "12px",
    textAlign: "center",
    backgroundImage: "linear-gradient(to left, #00fae7, #007d74)",
    opacity: isHovered ? 1 : 0.7,
    border: "none",
    fontWeight: 600,
    color: "white",
    transition: "all 300ms ease-in-out",
    
  };
  const secStyle = {
    borderRadius: "14.2px",
    fontFamily: "Metropolis",
    fontSize: "21px",
    textAlign: "center",
    fontWeight: 500,
    width: "215.2px",
    height: "44.8px",
    border: "solid 1px #00bd97",
    backgroundColor: "transparent",
    color: banner ? "white" : "black",
    opacity: isHovered ? 1 : 0.7,
    transition: "all 500ms ease-in-out",
  };
  const addStyle = {
    borderRadius: "14.2px",
    fontFamily: "Metropolis",
    fontSize: "16px",
    textAlign: "center",
    fontWeight: 500,
    color: isHovered ? "white" : "black",
    backgroundImage: isHovered
      ? "linear-gradient(to left, #00fae7, #007d74)"
      : "linear-gradient(to left top, #00fae7 , #00ccbd , #00b4a7 , #007d74)",
    border: "none",
    opacity: isHovered ? 1 : 0.6,
    transition: "all 300ms ease-in-out",
    
  };
  const appliedStyle =
    (type === "buy") | (type === "search") | (type === "other")
      ? mainStyle
      : type === "add"
      ? addStyle
      : secStyle;
  return (
    <button
      style={appliedStyle}
      className="button"
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {finalLabel}
    </button>
  );
};

export default Button;
